<template>
  <div>
    <success-snackbar
      v-model="snackbar"
      :message="$t('shop.addedToCart')"
    />
    <ApolloMutation
      :mutation="(gql) => addItemToOrderQuery"
      :variables="{
        itemId: id,
        orderId: orderId,
        locale: countryCode,
      }"
      :update="updateStore"
      @done="onDone"
    >
      <template #default="{ mutate, loading }">
        <PrimaryButton
          v-if="!inCart || !proceedToCheckout"
          v-bind="$attrs"
          :class="buttonClasses"
          :disabled="loading || queryLoading"
          :loading="loading || queryLoading"
          elevation="0"
          @click="() => {
            if (!canAdd) {
              changeShowCartDrawer(true);
            }
            else {
              mutate();
            }
          }"
        >
          {{ inCart && canAdd ? ($t('shop.addToCart')+` (${quantity})`) : inCart ? inCartText : addToCartText }}
          <v-icon
            size="15"
            class="ml-2 has-glow"
          >
            $cart
          </v-icon>
        </PrimaryButton>
      </template>
    </ApolloMutation>
  </div>
</template>

<script>
import i18n from '@/i18n';
import OrderQueries from "@/graphql/OrderQueries";
import SuccessSnackbar from '@/components/SuccessSnackbar.vue';
import gql from "graphql-tag";
import { FORMATS } from "@/utils/Const";
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    SuccessSnackbar,
    PrimaryButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    simplifiedId: {
      type: String,
      required: true,
    },
    queryLoading: {
      type: Boolean,
      required: true,
    },
    inCartText: {
      type: String,
      required: false,
      default: i18n.t("shop.viewCart"),
    },
    addToCartText: {
      type: String,
      required: false,
      default: i18n.t("shop.addToCart"),
    },
    proceedToCheckout: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonClasses: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      snackbar: false,
    }
  },
  apollo: {
    order: {
      query: gql`
        query getCartItems($orderId: ID!) {
          order(id: $orderId) {
            id
            orderLines(first: 1000) {
              edges {
                node {
                  id
                  productId
                  format
                  quantity
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          orderId: this.orderId,
        };
      },
      skip() {
        return !this.orderId;
      },
    },
  },
  computed: {
    addItemToOrderQuery() {
      return OrderQueries.addItemToOrder;
    },
    orderId() {
      return this.$store.state.cart.currentCart;
    },
    inCart() {
      if (this.order != null && this.orderId) {
        return this.order.orderLines.edges.find((edge) => edge.node.productId === this.simplifiedId);
      }

      return null;
    },
    canAdd() {
      if (this.inCart) {
        return this.inCart.node.format === FORMATS.print;
      }
      return true;
    },
    quantity() {
      if (this.inCart) {
        return this.order.orderLines.edges.find((edge) => edge.node.productId === this.simplifiedId).node.quantity;
      }

      return 0;
    },
    countryCode() {
      return this.$store.state.locale.countryCode;
    },
  },
  methods: {
    ...mapActions('siteFunctionality', ['changeShowCartDrawer']),
    async updateStore(store, {data: {addItemToOrder: {order}}}) {
      if (order.id !== this.orderId) {
        this.$store.commit("setCurrentCart", order.id);
        this.$store.commit('setCartOwner', this.$store.state.auth.getMe);
      }
    },
    onDone() {
      if (this.proceedToCheckout) {
        this.$router.push({ name: 'checkout' });
      } else {
        this.snackbar = true;
      }
    },
  },
};
</script>
