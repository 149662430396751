<template>
  <v-container>
    <ApolloQuery
      :query="
        (gql) => gql`
          query getBookById($id: ID!) {
            book(id: $id) {
              id
              _id
              title
              authors
              tagIds
              description
              imageUrl
              description
              price
              currency {
                id
                _id
              }
              modified
              publisher
              language
              thumbnailUrl
              format
              audioPreviewUrl
            }
          }
        `
      "
      :variables="{
        id: bookId,
      }"
      :update="
        (data) => {
          checkUrlExists(data.book?.audioPreviewUrl);
          return data.book;
        }
      "
    >
      <template #default="{ result: { error, data }, isLoading }">
        <v-container class="pa-0">
          <apollo-error-alert
            v-if="error"
            :error="error"
          />
          <BookInfoLoader v-if="isLoading" />
          <div v-else-if="data">
            <div class="d-flex">
              <div class="left-column pa-0">
                <v-img :src="data.imageUrl">
                  <template #placeholder>
                    <image-loading />
                  </template>
                </v-img>
              </div>
              <div
                class="d-flex flex-column justify-space-between pa-0 py-sm-2 py-md-4 pl-3 pl-sm-5 pl-md-11"
              >
                <login-to-view-modal :should-show="!!$route.params.fromEmail" />
                <div>
                  <h1 class="text-subtitle-1 text-md-h4 font-weight-bold">
                    {{ decodeHtml(data.title) }}
                  </h1>
                  <div v-if="data.authors && data.authors.length > 0">
                    <h3 class="text-subtitle-2 text-md-h5 font-weight-regular">
                      <span>
                        {{ $t("bookInfo.by") }}
                      </span>
                      <span
                        v-for="(author, index) in data.authors"
                        :key="index"
                      >
                        {{
                          index + 1 == data.authors.length
                            ? decodeHtml(author)
                            : $t("bookInfo.authorListItem", {
                              author: decodeHtml(author),
                            })
                        }}
                      </span>
                    </h3>
                  </div>
                  <h3
                    v-else
                    class="book-info__author"
                  >
                    {{ $t("bookInfo.authorUnknown") }}
                  </h3>
                  <add-to-book-list-modal
                    v-if="isLoggedIn"
                    class="my-2"
                    :book-id="data._id"
                  />
                </div>
                <div
                  v-if="data?.audioPreviewUrl && hasPreviewUrl"
                  class="my-3"
                >
                  <audio-button :audio-url="data.audioPreviewUrl" />
                </div>
                <div>
                  <price
                    class="text-subtitle-1 text-md-h5 mb-2"
                    :book-price="Number(data.price)"
                    :base-currency="data.currency._id"
                    include-tax
                    :should-round="true"
                  />
                  <open-book-button
                    v-if="ownedBook"
                    class="text-subtitle-2"
                    :read-url="ownedBook.readUrl"
                    :format="data?.format"
                    height="36px"
                    :min-width="buttonWidth"
                    :block="$vuetify.breakpoint.smAndDown"
                    rounded
                  />
                  <add-to-library-button
                    v-else-if="data.price == 0"
                    button-classes="text-subtitle-2"
                    :product-id="data._id"
                    :query-loading="bookshelfLoading"
                    height="36px"
                    :min-width="buttonWidth"
                    :block="$vuetify.breakpoint.smAndDown"
                    rounded
                    :refetch-bookshelf="refetchBookshelf"
                  />
                  <add-to-cart-button
                    v-else
                    :id="data.id"
                    button-classes="text-subtitle-2"
                    :simplified-id="data._id"
                    :query-loading="bookshelfLoading"
                    height="36px"
                    :min-width="buttonWidth"
                    :block="$vuetify.breakpoint.smAndDown"
                    rounded
                  />
                </div>
                <read-more
                  class="d-none d-md-block pt-2"
                  :text="data.description"
                />
              </div>
            </div>
            <read-more
              class="d-md-none py-5"
              :text="data.description"
            />
            <div class="d-flex flex-column flex-md-row pt-5 ">
              <div class="left-column details-column pb-5">
                <book-details :book="data" />
              </div>
              <div class="d-flex flex-column flex-grow-1 overflow-hidden pl-0 mx-n3 mx-md-0 pl-md-11">
                <ScreenViewHeader
                  class="mx-n3 mx-md-0 px-5 px-md-0"
                  :title="$t('shop.relatedBooks')"
                />
                <related-book-list
                  class="px-3 px-md-0"
                  :book-id="$route.params.id"
                  :tag-ids="data.tagIds"
                  :number-to-show="12"
                />
              </div>
            </div>
          </div>
        </v-container>
      </template>
    </ApolloQuery>
  </v-container>
</template>

<script>
import RelatedBookList from "@/components/bookList/RelatedBookList";
import ReadMore from "@/components/ReadMore";
import AddToCartButton from "@/views/checkout/AddToCartButton";
import Price from "@/components/Price";
import { decodeHtml } from "@/helpers/generalFunctions";
import BookDetails from "@/components/BookDetails";
import ApolloErrorAlert from "@/components/ApolloErrorAlert";
import ImageLoading from "@/components/ImageLoading";
import AddToLibraryButton from "@/components/AddToLibraryButton";
import OpenBookButton from "@/components/OpenBookButton";
import AddToBookListModal from "@/components/AddToBookListModal";
import LoginToViewModal from "@/components/LoginToViewModal";
import AudioButton from "@/components/AudioButton";
import BookInfoLoader from "@/views/skeletonLoaders/BookInfoLoader.vue";
import ScreenViewHeader from "@/components/ScreenViewHeader.vue";
import BookshelfMixin from "@/mixins/BookshelfMixin";

var images = require.context("@/assets/img/", false, /\.jpeg$/);

export default {
  components: {
    AddToCartButton,
    RelatedBookList,
    Price,
    ReadMore,
    BookDetails,
    ApolloErrorAlert,
    ImageLoading,
    AddToLibraryButton,
    OpenBookButton,
    AddToBookListModal,
    LoginToViewModal,
    AudioButton,
    BookInfoLoader,
    ScreenViewHeader,
  },
  mixins: [BookshelfMixin],
  data() {
    return {
      bookTagIds: [],
      hasPreviewUrl: false,
    };
  },
  computed: {
    bookId() {
      return this.$route.params.id;
    },
    ownedBook() {
      return this.checkInBookshelf(this.bookId);
    },
    isLoggedIn() {
      return this.$auth.isAuthenticated;
    },
    buttonWidth() {
      return this.$vuetify.breakpoint.smAndDown ? "fit-content" : "200px";
    },
  },
  methods: {
    imgUrl: function (path) {
      return images("./" + path);
    },
    decodeHtml: function (html) {
      return decodeHtml(html);
    },
    async checkUrlExists(url) {
      try {
        const response = await fetch(url, { method: "HEAD" });
        if (response.ok) {
          this.hasPreviewUrl = true;
        }
      } catch (error) {
        console.error("Error checking audio preview URL:", error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .left-column {
  min-width: 250px;
  width: 250px;
}

::v-deep .header-border {
  border-top: none;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  ::v-deep .left-column {
    min-width: 148px;
    width: 148px;
  }

  ::v-deep .details-column {
    width: 100%;
    max-width: 100%;
  }
}
</style>
