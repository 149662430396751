<template>
  <div
    class="px-1 transparent"
  >
    <div class="pa-2 rounded lightAccent border--light elevation-4">
      <apply-coupon
        v-if="!order.orderCoupon || true"
        :existing-coupon-code="order?.orderCoupon?.code"
        :order-id="order.id"
        @snackbarMessage="setSnackbarMessage"
      />
    </div>
    <div class="px-2 py-3 rounded lightAccent border--light elevation-4">
      <table class="d-flex flex-column">
        <tr
          v-if="order.orderCoupon"
          class="coupon-header mb-2"
        >
          <td>
            <div class="d-flex align-center">
              <span>{{ $t("checkout.hasCoupon") }}</span>
              <RemoveCoupon
                class="ml-3"
                :order-id="order.id"
                @snackbarMessage="setSnackbarMessage"
              />
            </div>
          </td>
          <td class="price-data">
            <span>{{ order.orderCoupon.code }}</span>
            <div style="font-weight: normal">
              {{ order.orderCoupon.description }}
            </div>
          </td>
        </tr>
        <tr>
          <td>{{ $t("checkout.itemsTotal") }}</td>
          <td class="price-data">
            <price
              :book-price="Number(order.totalLineItemsPrice)"
              :base-currency="order.currency._id"
              :show-free-text="false"
            />
          </td>
        </tr>
        <tr
          v-if="order.totalDiscount > 0"
          class="discount-text"
        >
          <td>{{ $t("checkout.discount") }}</td>
          <td class="price-data">
            <price
              :book-price="Number(order.totalDiscount)"
              :base-currency="order.currency._id"
              :show-free-text="false"
            />
          </td>
        </tr>
        <tr>
          <td>{{ $t("checkout.subtotal") }}</td>
          <td class="price-data">
            <price
              :book-price="Number(order.subtotalPrice)"
              :base-currency="order.currency._id"
              :show-free-text="false"
            />
          </td>
        </tr>
        <tr>
          <td>{{ $t("checkout.vat") }}</td>
          <td class="price-data">
            <price
              :book-price="Number(order.totalTax)"
              :base-currency="order.currency._id"
              :show-free-text="false"
            />
          </td>
        </tr>
        <tr>
          <td>
            {{ $t("checkout.total") }}
          </td>
          <td class="price-data">
            <price
              :book-price="Number(order.totalPrice)"
              :base-currency="order.currency._id"
            />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import ApplyCoupon from '@/components/coupons/ApplyCoupon';
import Price from '@/components/Price';
import RemoveCoupon from '@/components/coupons/RemoveCoupon.vue';

export default {
  name: "OrderTotals",
  components: {
    ApplyCoupon,
    Price,
    RemoveCoupon,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    setSnackbarMessage: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
tr {
  display: flex;
  justify-content: space-between;
}

td {
  color: var(--v-quaternaryText-base);
  font-weight: bold;
  font-size: 0.875rem;
}

::v-deep .gradient-text {
  -webkit-text-fill-color: var(--v-quaternaryText-base) !important;
  background: transparent;
}

::v-deep .discount-text .gradient-text {
  -webkit-text-fill-color: var(--v-success-base) !important;
}
</style>