<template>
  <div>
    <div v-if="$apollo.queries.book.loading">
      <div class="d-flex align-center">
        <v-skeleton-loader
          type="image"
          width="32px"
          height="44px"
          class="book-cover-image mr-6"
        />
        <v-skeleton-loader
          type="text@2"
          class="flex-grow-1"
        />
      </div>
    </div>
    <apollo-error-alert
      v-else-if="bookError"
      :error="bookError"
    />
    <div
      v-else
      class="d-flex align-center"
    >
      <v-img
        class="book-cover-image mr-6"
        :src="book.thumbnailUrl"
      >
        <template #placeholder>
          <image-loading />
        </template>
      </v-img>
      <div class="details-column mr-3">
        <h3 class="text-caption font-weight-bold">
          {{ decodeHtml(orderLine.name) }}
        </h3>
        <div
          v-if="book.authors && book.authors.length > 0"
          class="d-flex align-center"
        >
          <p class="text-caption font-weght-medium mr-1 mb-0">
            {{ $t("bookInfo.by") }}
          </p>
          <p
            v-for="(author, index) in authorList"
            :key="author"
            class="text-caption font-weght-medium mb-0 mr-1"
          >
            {{
              index + 1 == authorList.length
                ? decodeHtml(author)
                : $t("bookInfo.authorListItem", { author: decodeHtml(author) })
            }}
          </p>
          <p
            v-if="remainingAuthorsCount > 0"
            class="text-caption font-weght-medium mb-0"
          >
            {{ $t("bookInfo.moreAuthors", { count: remainingAuthorsCount }) }}
          </p>
        </div>
        <p
          v-else
          class="text-caption font-weght-medium mb-0"
        >
          {{ $t("bookInfo.authorUnknown") }}
        </p>
      </div>
      <div class="ml-auto">
        <div>
          <price
            :class="'text-caption font-weight-medium ml-auto '+(hasDiscount ? ' striked-text ' : '')"
            :book-price="Number(orderLine.preTaxPrice * orderLine.quantity)"
            :base-currency="orderCurrency._id"
            include-tax
            should-round
          />
          <price
            v-if="hasDiscount"
            class="text-caption font-weight-medium ml-auto"
            :book-price="Number(orderLine.price)"
            :base-currency="orderCurrency._id"
          />
        </div>
        <h5
          v-if="hasDiscount"
          class="text-caption discount-text"
        >
          {{ $t('checkout.hasCoupon') }}
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import { decodeHtml } from "@/helpers/generalFunctions";
import Price from "@/components/Price";
import ImageLoading from "@/components/ImageLoading";
import gql from "graphql-tag";
import ApolloErrorAlert from "@/components/ApolloErrorAlert.vue";

export default {
  name: "OrderBookDisplayItem",
  components: {
    Price,
    ImageLoading,
    ApolloErrorAlert,
  },
  props: {
    orderLine: {
      type: Object,
      required: true,
    },
    orderCurrency: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      book: {},
      authorsShownCount: 2,
      bookError: null,
    };
  },
  computed: {
    authorList() {
      return this.book.authors.slice(0, this.authorsShownCount);
    },
    remainingAuthorsCount() {
      return this.book.authors.length - this.authorList.length;
    },
    hasDiscount() {
      return this.orderLine.discount && this.orderLine.discount > 0;
    },
  },
  apollo: {
    book: {
      query: gql`
        query Book($id: ID!) {
          book(id: $id) {
            id
            thumbnailUrl
            authors
          }
        }
      `,
      variables() {
        return {
          id: "/books/" + this.orderLine.productId,
        };
      },
      error(error) {
        this.bookError = error;
      },
    },
  },
  methods: {
    decodeHtml,
  },
};
</script>

<style scoped>
.book-cover-image {
  max-width: 32px;
  max-height: 44px;
  object-fit: contain;
}

.discount-text {
  color: var(--v-success-base);
}

.striked-text {
  text-decoration: line-through;
}
</style>
