<template>
  <div class="banner-container">
    <div class="banner-text-container">
      <div class="banner-text">
        <img
          class="sun-image"
          src="@/assets/img/sun_lines.png"
        >
        <div class="d-flex align-center justify-center">
          <img
            class="arrow-icon"
            src="@/assets/img/stylized_arrow.png"
          >
          <h3 class="banner-dates mb-1">
            {{ $t("septemberBanner.dates") }}
          </h3>
        </div>
        <h1 class="banner-title">
          {{ $t("septemberBanner.title") }}
        </h1>
        <h3 class="banner-subtitle mb-5">
          {{ $t("septemberBanner.subtitle") }}
        </h3>
        <div class="d-flex align-center justify-end mr-16">
          <img
            class="arrow-icon"
            src="@/assets/img/stylized_arrow.png"
          >
          <v-btn
            color="background"
            class="shop-now-button rounded-pill"
            elevation="0"
            tile
            height="65px"
            width="280px"
            @click="
              $router.push({
                name: 'filteredBookList',
                params: {
                  id: featuredTagId,
                },
              })
            "
          >
            <span class="button-text">
              {{ $t("septemberBanner.shopNow") }}
            </span>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="banner-content">
      <div class="book-thumbnails">
        <div
          v-for="(book, index) in bookThumbnails.slice(0, 3)"
          :key="index"
          class="book-thumbnail-container"
          @click="navigateToPage(book.link)"
        >
          <v-img
            :src="require(`@/assets/img/thumbnails/${book.thumbnail}.png`)"
            class="book-thumbnail"
            object-fit="contain"
          />
        </div>
      </div>
      <div class="book-thumbnails">
        <div
          v-for="(book, index) in bookThumbnails.slice(3)"
          :key="index"
          class="book-thumbnail-container"
          @click="navigateToPage(book.link)"
        >
          <v-img
            :src="require(`@/assets/img/thumbnails/${book.thumbnail}.png`)"
            class="book-thumbnail"
            object-fit="contain"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AudiobookBanner",
  computed: {
    featuredTagId() {
      return 15;
    },
    windowBaseUrl() {
      return window.location.origin;
    },
    bookThumbnails() {
      return [
        {
          link: this.windowBaseUrl + "/search/dance%20of%20the%20monkeys",
          thumbnail: "monkeys",
        },
        {
          link: this.windowBaseUrl + "/search/when%20love%20says%20goodbye",
          thumbnail: "goodbye",
        },
        {
          link: this.windowBaseUrl + "/search/captive",
          thumbnail: "captive",
        },
        {
          link: this.windowBaseUrl + "/search/sinners",
          thumbnail: "sinners",
        },
        {
          link: this.windowBaseUrl + "/search/till%20death",
          thumbnail: "death",
        },
        {
          link: this.windowBaseUrl + "/search/Savannah",
          thumbnail: "savannah",
        },
      ];
    },
  },
  methods: {
    navigateToPage(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.banner-container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: Kanit, sans-serif;
  min-height: 550px;
  background-color: var(--v-quaternary-base);
}

.pattern-layer {
  position: absolute;
  width: 280px;
  bottom: 0;
  right: 0px;
}

.banner-content {
  flex-shrink: 1;
  flex-grow: 0;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 40px;
  margin: 0 auto;
}

.book-thumbnails {
  display: flex;
  align-items: center;
  gap: 10px;
}

.book-thumbnail {
  height: auto;
  object-fit: contain;
  height: 190px;
  width: 140px;
  cursor: pointer;
}

.banner-text-container {
  flex-grow: 1;
  background-image: url("@/assets/img/person_listening.png");
  background-size: contain;
  color: var(--v-textOnPrimary-base);
  position: relative;
  min-width: 770px;
  min-height: 550px;
  max-width: 100%;
}

.banner-text {
  position: absolute;
  right: 20px;
  transform: rotate(-6deg);
  font-weight: 700 !important;
  text-transform: uppercase;
  top: 30%;
}

.sun-image {
  position: absolute;
  bottom: 60px;
  left: 20px;
  width: 500px;
  z-index: -1;
  transform: rotate(6deg);
}

.banner-dates {
  font-size: 40px;
  line-height: 1;
  text-align: center;
}

.banner-title {
  font-weight: 700;
  font-size: 85px;
  line-height: 0.8;
  white-space: nowrap;
}

.banner-subtitle {
  font-weight: 700;
  font-size: 100px;
  line-height: 0.8;
  color: #fff615;
  white-space: nowrap;
}

.button-text {
  color: var(--v-quaternary-base);
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
}

.arrow-icon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .banner-container {
    min-height: auto;
  }

  .banner-text-container {
    min-height: 172px;
    min-width: auto;
    padding-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .banner-text {
    position: static;
    transform: scale(0.4) rotate(-6deg);
  }

  .banner-content {
    width: 100%;
    background-size: 80px;
  }

  .book-thumbnail {
    width: 75px;
    height: 112px;
    box-shadow: 0px 31px 23.1px -7px #ff000040;
  }

  .audio-icon {
    bottom: auto;
    top: 14px;
  }
}
</style>
