<template>
  <v-container
    class="fill-height d-flex overflow-hidden pa-0"
    style="max-height: 100%;"
  >
    <success-snackbar
      v-model="snackbar"
      :message="snackbarMessage"
    />
    <error-snackbar
      v-model="cartError"
      :error-message="$t('shop.orderAlreadyCompleted')"
    />
    <ApolloQuery
      :query="(gql) => getOrderQuery"
      :variables="{ orderId }"
      :skip="!orderId"
      :update="(data) => updateCart(data)"
      class="fill-height d-flex flex-column flex-grow-1 overflow-y-hidden"
    >
      <template #default="{ result: { data: order, error }, isLoading, query }">
        <div
          class="d-flex justify-space-between header-border"
          style="border-top: none;"
        >
          <ContinueShopping :handle-click="closeCart" />
          <empty-cart-button
            v-if="orderId && order && order.orderLines.edges.length > 0 && !order.confirmed"
            :order-id="orderId ?? ''"
            :is-disabled="!orderId || isLoading == 1 || order?.orderLines?.edges?.length == 0"
            @onRemoved="(event) => setSnackbarMessage(event)"
          />
        </div>
        <apollo-error-alert
          v-if="error"
          :error="error"
        />
        <v-list
          v-else-if="isLoading"
          class="flex-grow-1 d-flex flex-column"
          width="100%"
        >
          <v-list-item
            v-for="n in loadingItemsCount"
            :key="n"
            class="d-flex flex-column flex-grow-1"
            width="100%"
          >
            <v-skeleton-loader
              type="image"
              width="100%"
            />
            <v-skeleton-loader
              type="article"
              width="100%"
            />
          </v-list-item>
        </v-list>
        <div
          v-if="orderId && order && order.orderLines.edges.length > 0 && !order.confirmed"
          class="flex-grow-1 d-flex flex-column overflow-y-hidden"
        >
          <v-list class="px-3 py-2 flex-grow-1 overflow-auto">
            <h2 class="page-name primaryText--text subtitle-2 font-weight-bold">
              {{ $t("checkout.yourOrder") }}
            </h2>
            <v-list-item
              v-for="orderLine in order.orderLines.edges.map(
                (edge) => edge.node
              )"
              :key="orderLine.id"
              class="py-2 px-0"
            >
              <cart-list-item
                :order-line="orderLine"
                :order-id="order.id"
                :order-currency="order.currency"
                :show-quantity-input="true"
                @onRemoved="updateCache"
              />
            </v-list-item>
          </v-list>
          <OrderTotals
            :order="order"
            :set-snackbar-message="setSnackbarMessage"
          />
          <div class="button-container d-flex px-3 py-2">
            <PrimaryButton
              v-if="$auth.isAuthenticated"
              class="text-uppercase subtitle-2"
              block
              height="30"
              @click="checkout(query)"
            >
              {{ $t("checkout.checkout") }}
            </PrimaryButton>
            <PrimaryButton
              v-else
              class="text-uppercase subtitle-2"
              block
              height="30"
              @click="login"
            >
              {{ $t("checkout.loginAndCheckout") }}
              <v-icon
                size="18"
                class="ml-2"
              >
                mdi-arrow-right
              </v-icon>
            </PrimaryButton>
          </div>
        </div>
        <v-container
          v-else
          class="d-flex flex-column flex-grow-1 empty-cart-container text-center align-center justify-center"
        >
          <v-icon
            class="mb-1"
            large
          >
            $bookshelf
          </v-icon>
          <h2>
            {{ $t("shop.cartEmpty") }}
          </h2>
        </v-container>
      </template>
    </ApolloQuery>
  </v-container>
</template>

<script>
import SuccessSnackbar from "@/components/SuccessSnackbar";
import currencyMixins from "@/mixins/currencyMixins";
import CartListItem from "@/views/checkout/Cart/CartListItem";
import ApolloErrorAlert from "@/components/ApolloErrorAlert";
import ErrorSnackbar from "@/components/ErrorSnackbar";
import CouponMutations from "@/graphql/CouponMutations";
import EmptyCartButton from "./EmptyCartButton";
import gql from "graphql-tag";
import OrderFragments from "@/graphql/OrderFragments";
import OrderTotals from "@/views/checkout/Cart/OrderTotals";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import ContinueShopping from "@/components/ContinueShopping.vue";

var images = require.context("@/assets/img/", false, /\.jpeg$/);

const ORDER_DETAILS_FRAGMENT = OrderFragments.orderDetailsFragment;

export default {
  name: "CartScreen",
  components: {
    CartListItem,
    SuccessSnackbar,
    ApolloErrorAlert,
    ErrorSnackbar,
    EmptyCartButton,
    OrderTotals,
    PrimaryButton,
    ContinueShopping,
  },
  mixins: [currencyMixins],
  props: {
    closeCart: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      snackbar: false,
      snackbarMessage: "",
      loadingItemsCount: 6,
      cartError: false,
    };
  },
  computed: {
    getOrderQuery() {
      return gql`
        query GetOrder($orderId: ID!) {
          order(id: $orderId) {
            ...OrderDetails
          }
        }
        ${ORDER_DETAILS_FRAGMENT}
      `;
    },
    orderId() {
      return this.$store.state.cart.currentCart;
    },
  },
  methods: {
    imgUrl: function (path) {
      return images("./" + path);
    },
    async login() {
      await this.$auth.loginWithRedirect({
        appState: { targetUrl: "/checkout" },
      });
    },
    updateCart(data) {
      // remove coupon on empty cart
      if (
        data?.order?.orderLines?.edges?.length == 0 &&
        data?.order?.orderCoupon
      ) {
        this.$apollo.mutate({
          mutation: CouponMutations.removeCoupon,
          variables: {
            id: data.order.id,
          },
          update: () => {
            this.$apollo.query({
              query: this.getOrderQuery,
              variables: {
                orderId: this.orderId,
              },
              fetchPolicy: "network-only",
            });
          },
        });
      }
      if (!data) {
        this.$store.commit("setCurrentCart", null);
      }

      return data.order;
    },
    async checkout(query) {
      const result = await query.refetch();

      if (result && result.data.order.confirmed) {
        this.cartError = true;
      } else {
        this.$router.push({ name: "checkout" });
      }
    },
    setSnackbarMessage(value) {
      this.snackbarMessage = value;
      this.snackbar = true;
    },
    updateCache() {
      this.$apollo.query({
        query: this.getOrderQuery,
        variables: { orderId: this.orderId },
        fetchPolicy: "network-only",
      });

      this.setSnackbarMessage(this.$t("checkout.itemRemoved"));
    },
  },
};
</script>
