<template>
  <v-container
    style="max-width: 1200px;"
  >
    <ApolloQuery
      :query="(gql) => getOrderQuery"
      :variables="{ orderId }"
      :skip="!orderId"
      :update="(data) => data.order"
    >
      <template #default="{ result: { data, error }, isLoading }">
        <div v-if="error">
          <apollo-error-alert />
        </div>
        <v-container v-else-if="isLoading">
          <v-skeleton-loader type="article" />
        </v-container>
        <v-container
          v-else-if="data"
          class="align-center pa-0"
        >
          <div
            class="mx-auto fill-width"
            :style="`width: ${maxItemWidth};`"
          >
            <h2 class="text-subtitle-1 font-weight-medium mb-5">
              {{ $t("checkout.complete") }}
            </h2>
            <EkitabuExpansionPanel
              class="mb-5"
              accordion
              :value="$vuetify.breakpoint.smAndDown ? null : 0"
              :title="$t('checkout.orderNo', { orderId: data._id })"
            >
              <div
                class="d-flex flex-column pa-3"
                style="gap: 12px"
              >
                <OrderCompleteItem
                  v-for="orderLine in data.orderLines.edges.map((edge) => edge.node)"
                  :key="orderLine.id"
                  :order-line="orderLine"
                  :order-currency="data.currency"
                />
              </div>
              <div
                style="height: 2px; width: 100%;"
                class="lightAccent"
              />
              <div class="d-flex align-center jsutify-space0between pa-3 text-caption font-weight-bold">
                <div>
                  {{ $t("checkout.total") }}
                </div>
                <price
                  class="ml-auto"
                  :book-price="Number(data.totalPriceSet)"
                  :base-currency="data.currency._id"
                />
              </div>
            </EkitabuExpansionPanel>
            <div v-if="hasAudiobooks(data)">
              <p class="text-subtitle-2 font-weight-bold">
                {{ $t('audiobooks.clickAway') }}
              </p>
              <PrimaryButton
                block
                @click="() => openAudiobookApp()"
              >
                {{ $t("bookInfo.listenOnEkitabu") }}
                <v-icon
                  size="18"
                  class="ml-4 has-glow"
                >
                  $headphones
                </v-icon>
              </PrimaryButton>
            </div>
            <PrimaryButton
              v-else
              block
              :to="{ name: 'bookshelf' }"
            >
              {{ $t("bookshelf.viewOnBookshelf") }}
              <v-icon
                size="18"
                class="ml-3"
              >
                mdi-arrow-right
              </v-icon>
            </PrimaryButton>
            <IosAudiobookWarning
              :order="data"
            />
          </div>
          <order-status
            v-if="data?.orderFulfillment"
            class="mt-6"
            :order="data"
          />
        </v-container>
      </template>
    </ApolloQuery>
  </v-container>
</template>

<script>
import currencyMixins from "@/mixins/currencyMixins";
import ApolloErrorAlert from "@/components/ApolloErrorAlert";
import gql from "graphql-tag";
import { getProviderDetails } from '@/utils/PaymentProviderHelper';
import { FORMATS } from '@/utils/Const';
import OrderStatus from './OrderStatus';
import IosAudiobookWarning from '@/components/audioBooks/IosAudiobookWarning';
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import { openAudiobookApp } from "@/helpers/audiobookHelper";
import Price from "@/components/Price.vue";
import OrderCompleteItem from "@/components/lists/OrderCompleteItem.vue";
import EkitabuExpansionPanel from "@/components/EkitabuExpansionPanel.vue";

export default {
  components: {
    OrderCompleteItem,
    ApolloErrorAlert,
    OrderStatus,
    IosAudiobookWarning,
    PrimaryButton,
    Price,
    EkitabuExpansionPanel,
  },
  mixins: [currencyMixins],
  data() {
    return {
      orderId: this.$route.params.id,
    };
  },
  computed: {
    getOrderQuery() {
      return gql`
        query GetOrder($orderId: ID!) {
          order(id: $orderId) {
            id
            _id
            totalPriceSet
            shippingFeeSet
            updatedAt
            orderFulfillment {
              id
              fulfilledAt
              addressLine1
              addressLine2
              addressPostalCode
              phoneNumber
              altPhoneNumber
              school {
                id
                name
              }
              studentName
              studentClass
              createdAt
              updatedAt
            }
            safaricomMpesaRequests {
              id
              merchantRequestId
              resultCode
            }
            dpoPayments {
              id
              transactionReference
              resultCode
            }
            cashPayments {
              id
              succeededAt
            }
            currency {
              id
              _id
            }
            orderLines(first: 1000) {
              edges {
                node {
                  id
                  productId
                  name
                  preTaxPrice
                  price
                  discount
                  authorFirstName
                  authorLastName
                  format
                  quantity
                  baseCurrency {
                    id
                    _id
                  }              
                }
              }
            }
          }
        }
      `
    },
    formats() {
      return FORMATS;
    },
    maxItemWidth() {
      return "600px";
    }
  },
  methods: {
    formatDate(date) {
      let publicationDate = new Date(date);
      var options = { year: "numeric", month: "short", day: "numeric" };
      return publicationDate.toLocaleDateString("en-us", options);
    },
    getPaymentMethodName(data) {
      const paymentMethod = getProviderDetails(data);
      return this.$t(`paymentMethods.${paymentMethod?.provider || 'none'}`);
    },
    hasAudiobooks(order) {
      const orderLines = order.orderLines?.edges?.map(({ node }) => node);
      return !!orderLines.find(
        (orderLine) => orderLine.format == FORMATS.audio
      );
    },
  },
};
</script>
