<template>
  <v-container>
    <ApolloQuery
      :query="(gql) => getOrderQuery"
      :variables="{ orderId }"
      :skip="!orderId"
    >
      <template #default="{ result: { data, error }, isLoading }">
        <apollo-error-alert v-if="error" />
        <div v-else-if="isLoading">
          <v-skeleton-loader
            type="card@2"
            width="100%"
            min-height="400px"
          />
        </div>
        <div v-else-if="data">
          <div v-if="data.order.totalPrice > 0">
            <div class="d-flex align-center justify-space-between">
              <h2 class="text-subtitle-1 font-weight-medium">
                {{ $t("checkout.paymentMethod") }}
              </h2>
              <v-checkbox
                v-if="isAdmin || isChecker"
                v-model="isAdminOrder"
                :label="$t('checkout.adminOrder')"
                hide-details
                class="mt-0 text-subtitle-1"
              />
            </div>
            <v-radio-group
              v-model="paymentOption"
              class="mb-5 mt-3"
              hide-details
            >
              <v-row>
                <v-col class="col-6">
                  <PaymentMethodRadio
                    v-model="paymentOption"
                    :selected="paymentOption == paymentTypes.mpesa"
                    :label="$t('checkout.mpesaOrder')"
                    :radio-value="paymentTypes.mpesa"
                  >
                    <template #content>
                      <v-img
                        contain
                        max-width="120"
                        :src="mpesaLogo"
                      />
                    </template>
                  </PaymentMethodRadio>
                </v-col>
                <v-col class="col-6">
                  <PaymentMethodRadio
                    v-model="paymentOption"
                    :selected="paymentOption == paymentTypes.creditCard"
                    :label="$t('checkout.dpoOrder')"
                    :radio-value="paymentTypes.creditCard"
                  >
                    <template #content>
                      {{ $t("checkout.dpoOrder") }}
                    </template>
                  </PaymentMethodRadio>
                </v-col>
                <v-col
                  v-if="isAdminOrder"
                  class="col-6"
                >
                  <PaymentMethodRadio
                    v-model="paymentOption"
                    :selected="paymentOption == paymentTypes.cashPayment"
                    :label="$t('checkout.cashOrder')"
                    :radio-value="paymentTypes.cashPayment"
                  >
                    <template #content>
                      {{ $t("checkout.cashOrder") }}
                    </template>
                  </PaymentMethodRadio>
                </v-col>
              </v-row>
            </v-radio-group>
          </div>
          <no-cost-order
            v-if="data.order.totalPrice == 0"
            :container-classes="formContainerClasses"
          />
          <mpesa
            v-else-if="paymentOption == paymentTypes.mpesa"
            :is-admin-order="isAdminOrder"
            :is-print-order="checkOrderHasPrintBooks(data)"
            :container-classes="formContainerClasses"
          />
          <credit-card
            v-else-if="paymentOption == paymentTypes.creditCard"
            :is-admin-order="isAdminOrder"
            :is-print-order="checkOrderHasPrintBooks(data)"
            :container-classes="formContainerClasses"
          />
          <cash
            v-else-if="paymentOption == paymentTypes.cashPayment"
            :is-print-order="checkOrderHasPrintBooks(data)"
            :container-classes="formContainerClasses"
          />
          <IosAudiobookWarning
            class="mt-10"
            :order="data?.order"
          />
        </div>
      </template>
    </ApolloQuery>
  </v-container>
</template>

<script>
import Mpesa from "./Mpesa";
import currencyMixins from "@/mixins/currencyMixins";
import ApolloErrorAlert from "@/components/ApolloErrorAlert";
import NoCostOrder from "./NoCostOrder";
import CreditCard from "./CreditCard";
import AdminCheckMixin from "@/mixins/AdminCheckMixin";
import { FORMATS } from "@/utils/Const";
import gql from "graphql-tag";
import OrderFragments from "@/graphql/OrderFragments";
import Cash from "./Cash";
import IosAudiobookWarning from "@/components/audioBooks/IosAudiobookWarning";
import PaymentMethodRadio from "./PaymentMethodRadio";

const mpesaLogoPath = require("@/assets/img/mpesa_logo.png");

const PAYMENT_TYPES = {
  mpesa: "mpesa",
  creditCard: "cc",
  cashPayment: "cash",
};

const ORDER_DETAILS_FRAGMENT = OrderFragments.orderDetailsFragment;

export default {
  name: "CheckoutScreen",
  components: {
    Mpesa,
    ApolloErrorAlert,
    NoCostOrder,
    CreditCard,
    Cash,
    IosAudiobookWarning,
    PaymentMethodRadio,
  },
  mixins: [currencyMixins, AdminCheckMixin],
  data() {
    return {
      mpesaLogo: mpesaLogoPath,
      paymentOption: PAYMENT_TYPES.mpesa,
      isAdminOrder: false,
    };
  },
  computed: {
    currentTabComponent: function () {
      return this.radioGroup;
    },
    orderId() {
      return this.$store.state.cart.currentCart;
    },
    getOrderQuery() {
      return gql`
        query GetOrder($orderId: ID!) {
          order(id: $orderId) {
            ...OrderDetails
          }
        }
        ${ORDER_DETAILS_FRAGMENT}
      `;
    },
    paymentTypes() {
      return PAYMENT_TYPES;
    },
    formContainerClasses() {
      return 'border-sm border-lightAccent py-5 px-4 rounded-md elevation-10 mb-4'
    }
  },
  watch: {
    isAdminOrder() {
      if (!this.isAdminOrder) {
        this.paymentOption = PAYMENT_TYPES.mpesa;
      }
    },
  },
  created() {
    if (!this.orderId) {
      this.$router.push({ name: "home" });
    }
  },
  methods: {
    checkOrderHasPrintBooks(data) {
      const orderLines = data.order.orderLines?.edges.map(({ node }) => node);
      return !!orderLines.find(
        (orderLine) => orderLine.format == FORMATS.print
      );
    },
  },
};
</script>

<style scoped lang="scss">
.checkout-heading-container {
  border-bottom: 1px solid var(--v-lightAccent-base);
}

.checkout-heading-container h1 {
  font-size: 1.5rem;
}

.checkout-section-container {
  background-color: var(--v-lightestAccent-base);
}

.payment-method-radio {
  font-size: 0.875px;
}

.payment-method-radio-active {
  font-weight: 500;
}

.checkout-section-header {
  font-size: 1.25rem;
  font-weight: 500;
}
</style>
