import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { icons } from "./icons";

Vue.use(Vuetify);

const palette = {
  grey: {
    lightest: "#f6f6f6",
    lighter: "#e4e4e4",
    light: "#d3d3d3",
    mediumLight: "#b6b6b6",
    normal: "#797979",
    normalDark: "65686b",
    dark: "#808080",
    darker: "#434343"
  },
  yellow: {
    normal: "#F0A617"
  },
  red: {
    light2: "#E43A41",
    light1: "#D2363C",
    normal: "#ed1c24",
    dark1: "#7E2024",
  },
  orange: {
    light1: "#F0A617",
    dark1: "#e85c25",
  },
  purple: {
    normal: "#A14572",
  },
  blue: {
    light: "#00AEEF",
    normal: "#0976B4",
    dark1: "#005794"
  },
  white: {
    normal: "#ffffff",
    offWhite: "#fbfbfb",
  },
  black: {
    light1: "#020000",
    normal: "#000000",
  },
};

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: palette.red.normal,
        secondary: palette.grey.normal,
        tertiary: palette.blue.dark1,
        tertiaryLight: palette.blue.light,
        quaternary: palette.orange.dark1,
        quaternaryLight: palette.orange.light1,
        background: palette.white.normal,
        backgroundAccent: palette.grey.light,
        backgroundSecondary: palette.white.offWhite,
        primaryText: palette.black.light1,
        secondaryText: palette.grey.mediumLight,
        tertiaryText: palette.grey.normalDark,
        quaternaryText: palette.grey.darker,
        textOnPrimary: palette.white.normal,
        textOnError: palette.white.normal,
        textDisabledLight: palette.white.normal,
        accent: palette.grey.light,
        lightAccent: palette.grey.lighter,
        lightestAccent: palette.grey.lightest,
        footerBackground: palette.grey.darker,
        featuredBookPurple: palette.purple.normal,
        featuredBookYellow: palette.yellow.normal,
        black: palette.black.normal,
      },
    },
  },
  breakpoint: {
    threshholds: {
      xs: 600,
      sm: 900,
    },
  },
  icons,
});
